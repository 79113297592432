import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import { createRouter, createWebHashHistory } from 'vue-router'
import App from './App.vue'
import { createStore } from 'vuex'

import Admin from './components/Admin.vue'
import Login from './components/Login.vue'

const routes = [
  { path: '/', component: Admin },
  { path: '/login', component: Login }
]

const router = createRouter({
  routes,
  history: createWebHashHistory()
})

// 创建一个新的 store 实例
const store = createStore({
  state () {
    return {
      userInfo: {}
    }
  },
  mutations: {
    increment (state:any) {
      state.count++
    }
  }
})

const app = createApp(App)
app.use(ElementPlus)
app.use(router)
app.use(store)
app.mount('#app')
