import { BaseService } from '@/core/BaseService'

export class WarnsService extends BaseService {
  public requestWarnsListApi (params:{currentPage?:number, pageSize?:number, hasCount?:boolean, platform?: string}) {
    const pageSize = params.pageSize === undefined ? 0 : params.pageSize
    const currentPage = params.currentPage === undefined ? 0 : params.currentPage
    const hasCount = params.hasCount === undefined ? true : params.hasCount
    return fetch(this.BASE_URL + `/posIntegration/warns?pageSize=${pageSize}&page=${currentPage}&hasCount=${hasCount}&platform=${params.platform}`, {
      headers: {
        ...this.getCommonRequestHeader()
      }
    })
      .then((response) => {
        if (response.status !== 200) {
          throw new Error('请求失败')
        }
        return response.json()
      })
  }
}
