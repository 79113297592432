export class BaseService {
  // readonly BASE_URL = 'http://localhost:7001'
  readonly BASE_URL = 'https://pos-integration-warns.muncho.co/apis/v1'

  protected getCommonRequestHeader () {
    return {
      'Content-Type': 'application/json'
    }
  }
}
