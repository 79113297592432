import { BaseService } from '@/core/BaseService'

export class UserService extends BaseService {
  public login (params:{fullPhoneNumber:string, verificationCode:string, keepCredentials?:boolean}) {
    return fetch(this.BASE_URL + '/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        fullPhoneNumber: params.fullPhoneNumber,
        verificationCode: params.verificationCode,
        keepCredentials: params.keepCredentials ? params.keepCredentials : false
      })
    })
      .then((response) => {
        if (response.status !== 200) {
          throw new Error('请求失败')
        }
        return response.json()
      })
  }

  public logout () {
    return fetch(this.BASE_URL + '/logout', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        if (response.status !== 200) {
          throw new Error('请求失败')
        }
        return response.json()
      })
  }

  public getUserInfo () {
    return fetch(this.BASE_URL + '/user')
      .then((response) => {
        if (response.status !== 200) {
          throw new Error('请求失败')
        }
        return response.json()
      })
  }
}
